import { KPI } from '@optitrac/components';
import { withAccessControl } from '../pages/service/with-access-control';
import { subSections } from '../pages/utils/constants';
import { getAccessToken } from '../pages/service/accessToken';
import { UserContext } from '../pages/service/userContext';
import { API } from '../pages/service/apiHandler';

type KpiProps = {
  selectedTab: number;
  code: number;
};

const Kpi = (props: KpiProps) => {
  return (
    <KPI
      section={subSections[props.code][props.selectedTab]}
      getAccessToken={getAccessToken}
      UserContext={UserContext}
      API={API}
    />
  );
};

export default withAccessControl(Kpi);
