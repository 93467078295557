/* eslint-disable @typescript-eslint/no-unused-vars */
import moment from 'moment';
import { tz } from 'moment-timezone';
import { DateRange } from 'react-day-picker';
import { decodeJwt, JWTPayload } from 'jose';
import { isNull, isUndefined } from 'lodash';
import { enUS } from 'date-fns/locale';
import { Asset } from './model';

const NEW_ZEALAND_TIMEZONE = 'Pacific/Auckland';

export let currentDateNZ: Date = tz(NEW_ZEALAND_TIMEZONE).toDate(); // Upper Limit of the date or the current date in New Zealand

setInterval(() => {
  currentDateNZ = tz(NEW_ZEALAND_TIMEZONE).toDate(); // Upper Limit of the date or the current date in New Zealand
}, 60 * 1000);

export const minDate: moment.Moment = process.env.REACT_APP_DMU_MIN_DATE //This date is the minimum date which can be selected or sent to the APIs
  ? moment(process.env.REACT_APP_DMU_MIN_DATE)
  : moment('2024-01-01');

export const formatAsNDigitNumber = (
  number: number,
  digits: number,
): string => {
  //added zeroes at start to make the y digit number to n digit number
  return number.toFixed(1).padStart(digits, '0');
};

export const getStartOfDateInNZT = (dateObject: Date): number => {
  // Returns the unix timestamp of start of the day i.e. 00:00 am of the received date adjusted to NZST
  return moment.tz(dateObject, NEW_ZEALAND_TIMEZONE).startOf('day').unix();
};

export const getEndOfDateInNZT = (dateObject: Date): number => {
  if (
    moment
      .tz(dateObject, NEW_ZEALAND_TIMEZONE)
      .isSame(moment.tz(currentDateNZ, NEW_ZEALAND_TIMEZONE), 'day')
  ) {
    // Returns current time if the date is current date adjusted to NZST
    return moment().tz(NEW_ZEALAND_TIMEZONE).unix();
  } else {
    return moment.tz(dateObject, NEW_ZEALAND_TIMEZONE).endOf('day').unix();
  }
};

export const getFirstAndLastDayOfMonth = (date: Date): DateRange => {
  // Returns the 1st and last day of the month extracted from the received date object
  const momentDate: moment.Moment = moment(date);

  const firstDayOfMonth: moment.Moment = momentDate.clone().startOf('month');
  const lastDayOfMonth: moment.Moment = momentDate.clone().endOf('month');

  const earliestAvailableDateForMonth: Date = firstDayOfMonth.isBefore(minDate)
    ? minDate.toDate()
    : firstDayOfMonth.toDate();

  const lastAvailableDateForMonth: Date =
    momentDate.isSame(moment(currentDateNZ), 'month') &&
    momentDate.isSame(moment(currentDateNZ), 'year')
      ? currentDateNZ
      : lastDayOfMonth.toDate();

  const dateRange: DateRange = {
    from: earliestAvailableDateForMonth,
    to: lastAvailableDateForMonth,
  };

  return dateRange;
};

export const isFullMonth = (range: DateRange | undefined): boolean => {
  if (!range || !range.from || !range.to) {
    return false;
  }

  const fromDate: moment.Moment = moment(range.from);
  const toDate: moment.Moment = moment(range.to);

  if (!fromDate.isSame(toDate, 'month') || !fromDate.isSame(toDate, 'year')) {
    return false;
  }
  const firstDay: moment.Moment = fromDate.clone().startOf('month');
  const lastDay: moment.Moment = fromDate.clone().endOf('month');

  return fromDate.isSame(firstDay, 'day') && toDate.isSame(lastDay, 'day');
};

export const subtractOneMonth = (date: Date): Date => {
  const momentDate: moment.Moment = moment(date);
  const lastMonthDate: moment.Moment = momentDate.subtract(1, 'months');
  const day: number = date.getDate();

  if (momentDate.isBefore(minDate)) {
    return minDate.toDate();
  }

  if (lastMonthDate.date() !== day) {
    lastMonthDate.date(day);
  }
  return lastMonthDate.toDate();
};

export const convertMinutesToHHMM = (mins: number): string => {
  const totalMinutes = Math.round(mins);
  const hours: number = Math.floor(totalMinutes / 60);
  const minutes: number = totalMinutes % 60;
  const formattedHours = hours.toString().padStart(2, '0');
  const formattedMinutes = minutes.toString().padStart(2, '0');
  return `${formattedHours}:${formattedMinutes}`;
};

export const getHoursInRange = (dateRange: DateRange | undefined): number => {
  if (!dateRange) return 0;
  const now: moment.Moment = moment.tz(currentDateNZ, NEW_ZEALAND_TIMEZONE);
  const from: moment.Moment = moment
    .tz(dateRange.from, NEW_ZEALAND_TIMEZONE)
    .startOf('day');
  const to: moment.Moment | undefined = dateRange.to
    ? moment.tz(dateRange.to, NEW_ZEALAND_TIMEZONE).endOf('day')
    : undefined;
  if (from.isSame(now, 'day') && (!to || to.isSame(now, 'day'))) {
    return now.hours() + now.minutes() / 60;
  } else if (!from.isSame(now, 'day') && (!to || to.isSame(now, 'day'))) {
    if (to && to.isSame(now, 'day')) {
      return moment.duration(now.diff(from)).asHours();
    } else if (!to) {
      return 24;
    }
  } else if (!from.isSame(now, 'day') && to && !to.isSame(now, 'day')) {
    return moment.duration(to.diff(from)).asHours();
  }
  return 0;
};

export const sortDevices = (devices: Asset[]): Asset[] => {
  // Function to extract the integer part from the name
  const extractNumberFromName = (name: string): number => {
    const match = name.match(/CB(\d+)/);
    return match ? parseInt(match[1], 10) : 0;
  };

  // Separate the DMU entries from the CB entries
  const dmuDevices: Asset[] = devices.filter(
    (device: Asset) => !device.name.startsWith('CB'),
  );
  const cbDevices: Asset[] = devices.filter((device: Asset) =>
    device.name.startsWith('CB'),
  );

  // Sort the CB entries by the integer part of the name
  cbDevices.sort((asset1: Asset, asset2: Asset) => {
    const asset1Number: number = extractNumberFromName(asset1.name);
    const asset2Number: number = extractNumberFromName(asset2.name);
    return asset2Number - asset1Number;
  });

  // Merge the sorted CB entries with the DMU entries
  return [...dmuDevices, ...cbDevices];
};

export const isJwtExpired = (token: string): boolean => {
  try {
    const decodedToken: JWTPayload = decodeJwt(token);
    if (isNull(decodedToken) || isUndefined(decodedToken.exp)) {
      // Consider the token expired if it doesn't have an exp claim
      console.log('No token found');
      return true;
    }
    const currentTime: number = Math.floor(Date.now() / 1000);
    const isTokenExpired = decodedToken.exp < currentTime;
    console.log('Token expired: ', isTokenExpired);
    return isTokenExpired;
  } catch (error) {
    console.error('Failed to decode JWT:', error);
    // Consider the token expired if there's an error
    return true;
  }
};

export const areSameDate = (date1: Date, date2: Date): boolean => {
  const momentDate1: moment.Moment = moment(date1);
  const momentDate2: moment.Moment = moment(date2);
  return momentDate1.isSame(momentDate2, 'date');
};

export const joinWithAnd = (
  objects: { [key: string]: any }[],
  key: string,
): string => {
  if (!objects || objects.length === 0) {
    return '';
  }

  const values: string[] = objects.map(
    (obj: { [key: string]: any }) => obj[key],
  );

  if (values.length === 1) {
    return values[0];
  }

  const last = values.pop();
  return values.join(', ') + ' & ' + last;
};

export const customLocale: Locale = {
  ...enUS,
  localize: {
    ...enUS.localize,
    day: (n: 0 | 1 | 2 | 3 | 4 | 5 | 6): string =>
      ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'][n],
    ordinalNumber: enUS.localize?.ordinalNumber ?? ((n: number) => `${n}`),
    era: enUS.localize?.era ?? ((n: number) => `${n}`),
    quarter: enUS.localize?.quarter ?? ((n: number) => `${n}`),
    month: enUS.localize?.month ?? ((n: number) => `${n}`),
    dayPeriod: enUS.localize?.dayPeriod ?? ((n: number) => `${n}`),
  },
};
